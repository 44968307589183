
import { Vue, Options } from 'vue-class-component';
import { BaseButton, TriageSelect } from '@/lib/components';
import { CodeableConcept } from '@/lib';
import { Patient, TriageStatus } from '@/models';
import {
  EpisodeOfCareResponseData,
  EpisodeOfCareEncounter as Encounter
} from '@/models/episode-of-care/episode-of-care.model';
import { EncounterService, TriageStatusService, EpisodeOfCareService, CodeableConceptService } from '@/services/api';
import WorkflowLayout from '@/lib/layouts/WorkflowLayout.vue';
import ScansPage from '@/views/image-review/ScansPage.vue';
import Diagnosis from '@/views/image-review/Diagnosis.vue';
import Management from '@/views/image-review/Management.vue';
import PatientSummary from '@/views/image-review/PatientSummary.vue';
import Correspondence from '@/views/image-review/Correspondence.vue';
import Confirmation from '@/views/image-review/Confirmation.vue';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { codeableConceptCategories } from '@/constants';
import { useHistoryStore } from '@/stores/history.store';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  props: {
    patientId: {
      type: String,
      required: true
    },
    episodeOfCareId: {
      type: String,
      required: true
    },
    organisationId: {
      type: String,
      required: true
    },
    patient: {
      type: Object,
      default: null
    }
  },
  components: {
    TriageSelect,
    BaseButton,
    Confirmation,
    Correspondence,
    Diagnosis,
    Management,
    PatientSummary,
    ScansPage,
    WorkflowLayout
  }
})
export default class ImageReview extends Vue {
  encounterId = '';
  organisationId!: string;
  patientId!: string;
  episodeOfCareId!: string;
  episodeOfCare?: EpisodeOfCareResponseData;
  virtualEncounter?: Encounter;
  ambulatoryEncounter?: Encounter;
  documentId = '';
  managementPlanId = '';
  patient!: Patient | null;
  loading = true;
  patientCorrespondenceLoading = false;
  completeManagement = false; // TODO: update to true at the endpoint this review workflow

  historyStore = useHistoryStore();
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();
  encounterService = new EncounterService();
  episodeOfCareService = new EpisodeOfCareService();
  codeableConceptService = new CodeableConceptService();

  request!: CancelTokenSource | null;
  clinicId: string | undefined = '';
  triageStatuses: Array<TriageStatus> = [];
  triageStatusService: TriageStatusService = new TriageStatusService();
  triageStatusId: string | undefined = '';
  triageLoading = false;

  // Codeable Concepts
  encounterClasses: CodeableConcept[] = [];
  encounterStatuses: CodeableConcept[] = [];
  participantTypes: CodeableConcept[] = [];

  get steps(): string[] {
    return [
      this.$t('custom.uhb.review.summary.patient-summary'),
      this.$t('platform.patient.scans'),
      this.$t('custom.uhb.diagnosis.diagnosis'),
      this.$t('custom.uhb.management.management')
    ];
  }

  get step() {
    // We want the URL param to be 1-based, but the value in the component to be zero-based
    return Number(this.$route.query.step || 1) - 1;
  }

  async created() {
    try {
      this.encounterClasses = await this.fetchCodeableConceptsByCategory(
        codeableConceptCategories.encounterClass,
        this.$t('custom.uhb.episode-of-care.encounter-class-fetch-error')
      );
      this.encounterStatuses = await this.fetchCodeableConceptsByCategory(
        codeableConceptCategories.encounterStatus,
        this.$t('custom.uhb.episode-of-care.status-code-fetch-error')
      );
      this.participantTypes = await this.fetchCodeableConceptsByCategory(
        codeableConceptCategories.participantType,
        this.$t('custom.uhb.episode-of-care.participant-type-fetch-error')
      );

      await this.fetchEpisodeOfCare();

      this.clinicId = this.episodeOfCare?.ophthalmology_details.clinic.id;
      this.triageStatusId =
        this.episodeOfCare?.ophthalmology_details.triage_status?.id;

      // Set the ambulatory encounter
      const ambulatoryClassCode = this.findCodeableConceptByCode(
        'AMB',
        this.encounterClasses
      );
      this.ambulatoryEncounter = this.fetchEncounterByClass(
        ambulatoryClassCode?.id
      );

      // Set the virtual encounter
      const virtualClassCode = this.findCodeableConceptByCode(
        'VR',
        this.encounterClasses
      );
      this.virtualEncounter = this.fetchEncounterByClass(virtualClassCode?.id);
      this.encounterId = this.virtualEncounter?.id ?? '';

      if (this.clinicId && this.clinicId.length) {
        await this.fetchTriageStatuses(this.clinicId);
      }
    } catch (e) {
      this.notificationStore.addErrorNotification({
        title: this.$t('custom.uhb.review.loading-error')
      });
      this.cancel();
    } finally {
      this.loading = false;
    }
  }

  async fetchEpisodeOfCare() {
    try {
      this.loading = true;
      this.request = axios.CancelToken.source();
      this.episodeOfCare = await this.episodeOfCareService.fetch(
        this.episodeOfCareId,
        {
          params: {
            cancelToken: this.request.token
          }
        }
      );
    } catch (e) {
      if (!axios.isCancel(e)) {
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.episode-of-care.episode-of-care-fetch-error')
        });
      }
    } finally {
      this.request = null;
    }
  }

  fetchEncounterByClass(classId?: string) {
    return this.episodeOfCare?.encounters.find((e) => e.class === classId);
  }

  async fetchTriageStatuses(clinicId: string) {
    this.triageLoading = true;
    try {
      this.request = axios.CancelToken.source();
      const requestConfig: AxiosRequestConfig = {
        params: {
          ...(clinicId ? { 'filter[clinic_id]': clinicId } : {}),
          cancelToken: this.request.token
        }
      };

      this.triageStatuses = (
        await this.triageStatusService.index(requestConfig)
      ).data;
    } catch (e) {
      if (!axios.isCancel(e)) {
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.consult.error-fetching-triage-status')
        });
      }
    } finally {
      this.triageLoading = false;
      this.request = null;
    }
  }

  cancel() {
    let name = this.historyStore.from;
    if (!this.historyStore.from || this.historyStore.from?.startsWith('patient-image-review')) {
      name = 'patient-consults';
    }
    this.$router.push({
      name,
      params: { organisationId: this.organisationId, patientId: this.patientId }
    });
  }

  next() {
    // @ts-ignore
    this.$router.push({
      ...this.$route,
      query: {
        ...this.$route.query,
        step: String(this.step + 2) // Add 2 because the URL param is 1-based
      }
    });
  }

  // TODO: move these functions into a reusable location
  async getCodeableConceptByCategory(
    category: string
  ): Promise<CodeableConcept[]> {
    return (
      await this.codeableConceptService.index({
        params: {
          'filter[category]': category
        }
      })
    ).data;
  }

  // TODO: move these functions into a reusable location
  findCodeableConceptByCode(code: string, codeableConcepts: CodeableConcept[]) {
    return codeableConcepts.find(
      (codableConcept) => codableConcept.code === code
    );
  }

  // TODO: move these functions into a reusable location
  async fetchCodeableConceptsByCategory(
    category: string,
    errorMessage: string
  ): Promise<CodeableConcept[]> {
    let codeableConcepts: CodeableConcept[];
    try {
      this.loading = true;
      codeableConcepts = await this.getCodeableConceptByCategory(category);
    } catch (error) {
      await this.notificationStore.addErrorNotification({
        title: errorMessage,
        label: error.response?.data?.message || ''
      });
    } finally {
      this.loading = false;
    }

    return codeableConcepts;
  }

  back() {
    if (this.step === 0) {
      this.cancel();
      return;
    }

    // @ts-ignore
    this.$router.push({
      ...this.$route,
      query: {
        ...this.$route.query,
        step: String(this.step) // Add 2 because the URL param is 1-based
      }
    });
  }

  async complete() {
    const completeStatus = this.findCodeableConceptByCode(
      'finished',
      this.encounterStatuses
    );
    const reviewParticipantType = this.findCodeableConceptByCode(
      'REV',
      this.participantTypes
    );
    if (this.virtualEncounter?.id && completeStatus?.id) {
      const data = {
        status_id: completeStatus.id,
        participants: [{
          user_id: this.sessionStore.currentUser.id,
          participant_type_id: reviewParticipantType?.id,
          participant_type_code: reviewParticipantType?.code
        }]
      };
      await this.encounterService.update(this.virtualEncounter.id, data);
    }
    this.$router.push({
      name: 'patient',
      params: {
        organisationId: this.organisationId,
        patientId: this.patientId
      }
    });
  }

  beforeUnmount() {
    if (this.request) {
      this.request.cancel();
    }
  }
}
