import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "h-full"
}
const _hoisted_2 = {
  key: 0,
  class: "h-full"
}
const _hoisted_3 = {
  key: 1,
  class: "max-w-6xl mx-auto"
}
const _hoisted_4 = {
  key: 2,
  class: "max-w-6xl mx-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TriageSelect = _resolveComponent("TriageSelect")!
  const _component_portal = _resolveComponent("portal")!
  const _component_PatientSummary = _resolveComponent("PatientSummary")!
  const _component_ScansPage = _resolveComponent("ScansPage")!
  const _component_Diagnosis = _resolveComponent("Diagnosis")!
  const _component_Management = _resolveComponent("Management")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_WorkflowLayout = _resolveComponent("WorkflowLayout")!

  return (_openBlock(), _createBlock(_component_WorkflowLayout, {
    name: _ctx.$t('custom.uhb.review.ophthalmological'),
    steps: _ctx.steps,
    "current-step": _ctx.step,
    patient: _ctx.patient,
    onCancel: _ctx.cancel
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_BaseButton, {
        size: "large",
        color: "ghost",
        loading: _ctx.loading || _ctx.patientCorrespondenceLoading,
        type: "button",
        onClick: _ctx.back
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('platform.common.back')), 1)
        ]),
        _: 1
      }, 8, ["loading", "onClick"]),
      (_ctx.step < _ctx.steps.length - 1)
        ? (_openBlock(), _createBlock(_component_BaseButton, {
            key: 0,
            size: "large",
            class: "ml-2",
            "right-icon": "arrow-right",
            type: "submit",
            loading: _ctx.loading || _ctx.patientCorrespondenceLoading,
            disabled: 
          (_ctx.step >= 3 && !_ctx.managementPlanId)
        ,
            onClick: _withModifiers(_ctx.next, ["prevent"])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('platform.common.next-step')), 1)
            ]),
            _: 1
          }, 8, ["loading", "disabled", "onClick"]))
        : (_openBlock(), _createBlock(_component_BaseButton, {
            key: 1,
            "right-icon": "arrow-right",
            size: "large",
            color: "success",
            class: "ml-2",
            type: "submit",
            loading: _ctx.loading || _ctx.patientCorrespondenceLoading,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.complete()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('platform.common.complete')), 1)
            ]),
            _: 1
          }, 8, ["loading"]))
    ]),
    default: _withCtx(() => [
      _createVNode(_component_portal, { to: "triage" }, {
        default: _withCtx(() => [
          (_ctx.triageStatuses.length && _ctx.triageStatusId)
            ? (_openBlock(), _createBlock(_component_TriageSelect, {
                key: 0,
                modelValue: _ctx.triageStatusId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.triageStatusId) = $event)),
                options: _ctx.triageStatuses,
                loading: _ctx.triageLoading,
                "read-only": ""
              }, null, 8, ["modelValue", "options", "loading"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      (!_ctx.loading && _ctx.encounterId)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.step === 0)
              ? (_openBlock(), _createElementBlock("section", _hoisted_2, [
                  _createVNode(_component_PatientSummary, {
                    "patient-id": _ctx.patientId,
                    patient: _ctx.patient,
                    "episode-of-care": _ctx.episodeOfCare,
                    "ambulatory-encounter": _ctx.ambulatoryEncounter,
                    "virtual-encounter": _ctx.virtualEncounter,
                    "organisation-id": _ctx.organisationId
                  }, null, 8, ["patient-id", "patient", "episode-of-care", "ambulatory-encounter", "virtual-encounter", "organisation-id"])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("section", {
              class: _normalizeClass([{ hidden: _ctx.step !== 1 }, "mx-auto h-full"])
            }, [
              _createVNode(_component_ScansPage, {
                "patient-id": _ctx.patientId,
                patient: _ctx.patient,
                "encounter-id": _ctx.encounterId
              }, null, 8, ["patient-id", "patient", "encounter-id"])
            ], 2),
            (_ctx.step === 2)
              ? (_openBlock(), _createElementBlock("section", _hoisted_3, [
                  _createVNode(_component_Diagnosis, {
                    "patient-id": _ctx.patientId,
                    "encounter-id": _ctx.encounterId
                  }, null, 8, ["patient-id", "encounter-id"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.step === 3)
              ? (_openBlock(), _createElementBlock("section", _hoisted_4, [
                  _createVNode(_component_Management, {
                    "management-plan-id": _ctx.managementPlanId,
                    "onUpdate:management-plan-id": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.managementPlanId) = $event)),
                    "patient-id": _ctx.patientId,
                    "encounter-id": _ctx.encounterId,
                    "organisation-id": _ctx.organisationId,
                    "complete-management": _ctx.completeManagement
                  }, null, 8, ["management-plan-id", "patient-id", "encounter-id", "organisation-id", "complete-management"])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["name", "steps", "current-step", "patient", "onCancel"]))
}